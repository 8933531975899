<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0"
           @close="closeSysRoleDetailPage" width="850px" id="sysRoleDetailDialog"
           :close-on-click-modal="false" center :destroy-on-close=true>
        <el-form :model="sysRoleDto" :rules="formRules" ref="sysRoleForm" label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="归属机构" prop="orgId">
                        <doart-select-tree
                            :width="190"
                            :data="orgData"
                            :checkedKeys="checkedKeys"
                            clearable
                            @change="handleSelectTreeChangeEvent"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="角色名称" prop="roleName">
                        <el-input v-model="sysRoleDto.roleName" autocomplete="off" maxlength="50"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="角色编码" prop="roleCode">
                        <el-input :disabled="sysRoleDto.id !== null" v-model="sysRoleDto.roleCode" autocomplete="off" maxlength="50"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="角色类型" prop="roleType">
                        <el-select :disabled="sysRoleDto.id !== null" v-model="sysRoleDto.roleType" placeholder="请选择角色类型" style="width: 213px;">
                            <el-option v-for="item in roleTypeOption" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="菜单权限" prop="menuId" class="menu-tree">
                    <el-tree
                        :data="menuList"
                        show-checkbox
                        node-key="id"
                        ref="tree"
                        highlight-current
                        :props="defaultProps">
                    </el-tree>
                </el-form-item>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" type="primary" @click="saveOrModifySysRole">确定</el-button>
            <el-button size="mini" @click="detailDialogVisible = false">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import message from '@/utils/message';
    import {queryAssignableMenuList, querySysRoleById, saveOrModifySysRole} from '@/api/sys/roleApi';
    import global from '@/components/Global';
    import loading from '@/utils/cmdLoading';
    import DoartSelectTree from '@/components/doart-select-tree/doart-select-tree';

    export default {

        name: 'SysRoleDetail',
        components: {DoartSelectTree},
        data() {

            return {

                detailDialogVisible: false,
                detailDialogTitle: '',
                sysRoleDto: {

                    id: null,
                    roleName: null,
                    orgId: null,
                    roleCode: null,
                    roleType: 0,
                    menuIdList: []
                },
                orgData: [],
                checkedKeys: [],
                menuList: [],
                defaultProps: {

                    children: 'subMenuList',
                    label: 'name'
                },
                formRules: {

                    roleName: [
                        {required: true, message: '角色名称不可为空', trigger: 'blur'}
                    ],
                    orgId: [
                        {required: true, message: '归属机构不可为空', trigger: 'blur'}
                    ],
                    roleCode: [
                        {required: true, message: '角色编码不可为空', trigger: 'blur'}
                    ],
                    roleType: [
                        {required: true, message: '角色类型不可为空', trigger: 'change'}
                    ]
                },
                roleTypeOption: [
                    {
                        label: '私有角色',
                        value: 0
                    },
                    {
                        label: '公有角色',
                        value: 1
                    }
                ]
            }
        },
        methods: {

            initSysDetailPage(id, orgData) {

                this.detailDialogVisible = true;
                this.detailDialogTitle = '新增角色';
                this.sysRoleDto.id = null;
                this.orgData = orgData;
                this.queryAssignableMenuList();
                if (id !== null) {

                    // 查询角色信息详情
                    this.querySysRoleById(id);
                    this.detailDialogTitle = '编辑角色';
                    this.sysRoleDto.id = id;
                }
            },
            closeSysRoleDetailPage() {

                // 重置表单
                this.$refs['sysRoleForm'].resetFields();
                this.checkedKeys = [];
            },
            handleSelectTreeChangeEvent(selectedData) {

                this.sysRoleDto.orgId = selectedData;
            },
            querySysRoleById(id) {

                let queryLoading = loading.loading('加载中...', 30);
                let param = {

                    id: id
                };
                querySysRoleById(param).then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.sysRoleDto = res.data;
                        this.$refs.tree.setCheckedKeys(res.data.menuIdList);
                        this.checkedKeys = [res.data.orgId];
                        queryLoading.close();
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    console.error(err);
                    message.error(err);
                });
            },
            queryAssignableMenuList() {

                queryAssignableMenuList().then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.menuList = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    console.error(err);
                    message.error(err);
                })
            },
            saveOrModifySysRole() {

                this.$refs['sysRoleForm'].validate((valid) => {

                    if (valid) {

                        this.sysRoleDto.menuIdList = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys());
                        saveOrModifySysRole(this.sysRoleDto).then((res) => {

                            if (res.code === global.response_status_success_obj) {

                                this.detailDialogVisible = false;
                                this.$emit('querySysRoleList');
                                if (this.sysRoleDto.id === null) {

                                    message.success('新增成功');
                                } else {

                                    message.success('修改成功');
                                }
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            message.error('请求失败,请稍后再试');
                            console.error(err);
                        });
                    } else {

                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.menu-tree {

    height: 400px;
    display: block;
    overflow-y: auto;
}
</style>
